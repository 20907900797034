import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useState } from "react";
const ContractForm = ({ formType, handleChange, form, serverError }) => {
  const serverErrorMessage = (field) => {
    if (serverError[field]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", fontFamily: "font-normal" }}
        >
          {serverError[field][0]}
        </div>
      );
    }
  };
  return (
    <div style={{ width: "100%", direction: "rtl" }}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
      >
        <Grid item xs={12} md={6}>
          <div className="text23 mr0 f-normal">
            {formType === "renter" ? "اسم الشركة" : "اسم المكتب"}
          </div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 15,
              fontFamily: "font-normal",
            }}
          >
            <TextField
              fullWidth
              error={serverError.name}
              name="name"
              value={form.name}
              sx={{
                ".MuiOutlinedInput-input": {
                  color: "#014E97",
                  fontFamily: "font-normal",
                },
              }}
              onChange={(e) => handleChange("name", e.target.value)}
            />
            {serverErrorMessage("name")}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text23 mr0 f-normal">المدينة</div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 15,
              fontFamily: "font-normal",
            }}
          >
            <TextField
              error={serverError.city}
              fullWidth
              name="city"
              value={form.city}
              sx={{
                ".MuiOutlinedInput-input": {
                  color: "#014E97",
                  fontFamily: "font-normal",
                },
              }}
              onChange={(e) => handleChange("city", e.target.value)}
            />
            {serverErrorMessage("city")}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text23 mr0 f-normal">البريد الالكتروني</div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 15,
              fontFamily: "font-normal",
            }}
          >
            <TextField
              fullWidth
              error={serverError.email}
              name="email"
              value={form.email}
              sx={{
                ".MuiOutlinedInput-input": {
                  color: "#014E97",
                  fontFamily: "font-normal",
                },
              }}
              onChange={(e) => handleChange("email", e.target.value)}
            />
            {serverErrorMessage("email")}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text23 mr0 f-normal">
            {formType === "renter" ? "عدد الفروع" : "عدد العقارات"}
          </div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 15,
              fontFamily: "font-normal",
            }}
          >
            <TextField
              fullWidth
              error={serverError.branch_no}
              name="branch_no"
              value={form.branch_no}
              sx={{
                ".MuiOutlinedInput-input": {
                  color: "#014E97",
                  fontFamily: "font-normal",
                },
              }}
              onChange={(e) => handleChange("branch_no", e.target.value)}
            />
            {serverErrorMessage("branch_no")}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text23 mr0 f-normal">
            إسم ممثل {formType === "renter" ? "الشركة" : "الشركة / المؤسسة"}
          </div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 15,
              fontFamily: "font-normal",
            }}
          >
            <TextField
              fullWidth
              error={serverError.company_representer}
              name="company_representer"
              value={form.company_representer}
              sx={{
                ".MuiOutlinedInput-input": {
                  color: "#014E97",
                  fontFamily: "font-normal",
                },
              }}
              onChange={(e) =>
                handleChange("company_representer", e.target.value)
              }
            />
            {serverErrorMessage("company_representer")}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text23 mr0 f-normal">
            صفة ممثل {formType === "renter" ? "الشركة" : "الشركة / المؤسسة"}
          </div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 15,
              fontFamily: "font-normal",
            }}
          >
            <TextField
              fullWidth
              error={serverError.position_company_representer}
              name="position_company_representer"
              value={form.position_company_representer}
              sx={{
                ".MuiOutlinedInput-input": {
                  color: "#014E97",
                  fontFamily: "font-normal",
                },
              }}
              onChange={(e) =>
                handleChange("position_company_representer", e.target.value)
              }
            />
            {serverErrorMessage("position_company_representer")}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="text23 mr0 f-normal">رقم الجوال</div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 15,
              fontFamily: "font-normal",
            }}
          >
            <TextField
              fullWidth
              error={serverError.phone}
              name="phone"
              value={form.phone}
              sx={{
                ".MuiOutlinedInput-input": {
                  color: "#014E97",
                  fontFamily: "font-normal",
                },
              }}
              type="number"
              onChange={(e) => handleChange("phone", e.target.value)}
            />
            {serverErrorMessage("phone")}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContractForm;
