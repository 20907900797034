import "./features.css";
import ownerIcon from "../../assets/owner-icon.svg";
import renterIcon from "../../assets/renter-icon.svg";
import brokerIcon from "../../assets/broker-icon.svg";
import Grid from "@mui/material/Grid";

const Features = () => {
  return (
    <div className="features-root">
      <div className="features-box1">
        <div className="features-box3">
          <div className="features-text1">مزايا الانطلاق مع قسطار</div>
          <div className="features-text2">
            قسطار لديها عدد من الامتيازات تناسب إحتياجاتك
          </div>
        </div>
      </div>

      <div className="features-box2">
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item xs={12} sm={4}>
            <div className="why-box4 ">
              <div className="why-box5">
                <div className="why-text1">كوسيط عقاري</div>
                <div className="features-icon">
                  <img src={brokerIcon} alt="owner-i" />
                </div>
              </div>
              <div className="why-box6">
                <div className="why-box7">
                  <div className="why-text2 f-normal">{"منصة موثوقة ،"}</div>
                  <div className="why-text2 f-normal">
                    عمولة مالية من كل صفقة مبرمة،
                  </div>
                  <div className="why-text2 f-normal">.خيارات دفع متنوعة</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="features-box4 ">
              <div className="why-box5">
                <div className="features-text3">كمستأجر لعقار تجاري</div>
                <div className="features-icon">
                  <img src={renterIcon} alt="owner-i" />
                </div>
              </div>
              <div className="why-box6">
                <div className="why-box7">
                  <div className="features-text3 f-normal">
                    ،التواصل عبر منصة واحدة
                  </div>
                  <div className="features-text3 f-normal">
                    ،أقساط شهرية ميسرة
                  </div>
                  <div className="features-text3 f-normal">.تنظيم الدفعات</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="why-box4 ">
              <div className="why-box5">
                <div className="why-text1">كمالك لعقار</div>
                <div className="features-icon">
                  <img src={ownerIcon} alt="owner-i" />
                </div>
              </div>
              <div className="why-box6">
                <div className="why-box7">
                  <div className="why-text2 f-normal">
                    خدمة الفحص الائتماني للمستأجرين
                  </div>
                  <div className="why-text2 f-normal">التدفق المالي</div>
                  <div className="why-text2 f-normal">.السرية التامة</div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Features;
