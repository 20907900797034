import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import success from "../../assets/success.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SuccessPopup({ open, email, link }) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/");
  };

  const handleAgeeAndContinue = () => {
    navigate("/");
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
      >
        <DialogContent
          dividers
          sx={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1vmax",
            // width: "50vmin",
          }}
        >
          <img src={success} alt="success" style={{ width: "15vmax" }} />
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
              fontFamily: "font-normal",
              textAlign: "center",
            }}
          >
            <div style={{ fontSize: "1rem" }}>شكرًا لثقتم بنا</div>
            <div style={{ fontSize: "1rem" }}>تم إرسال المذكرة بنجاح</div>
            <div style={{ fontSize: "1rem" }}>
              سيتم إرسال نسخة من المذكرة على البريد الإلكتروني: {email}
            </div>
            <div
              style={{
                fontSize: "1rem",
                display: "flex",
                gap: 5,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <a href={`https://app.qistar.sa/storage/${link}`} target="_blank">
                اضغط هنا
              </a>{" "}
              لتحميل المذكرة
            </div>
          </div>
          <Button
            onClick={handleAgeeAndContinue}
            variant="contained"
            size="large"
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              bgcolor: "#014E97",
            }}
          >
            <div className="text3 f-normal">متابعة</div>
          </Button>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
