import {
  Button,
  Grid,
  Container,
  Drawer,
  IconButton,
  Box,
  Divider,
  ListItemButton,
  ListItem,
  List,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import image2 from "../../assets/image2.svg";

const drawerWidth = 240;
const navItems = [
  { name: "الرئيسية", link: "/" },
  { name: "مذكرة تفاهم", link: "/memorandum" },
];

const AppBar = ({ image1 }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ my: 2 }}>
        <img
          src={image2}
          alt="qistar"
          style={{
            width: "7rem",
            height: "2.43rem",
          }}
        />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "right",
                direction: "rtl",
                fontFamily: "font-normal",
              }}
              onClick={() => navigate(item.link)}
            >
              {/* <ListItemText primary={item} sx={{ fontFamily: "pun-bold" }} /> */}
              <div style={{ fontFamily: "font-normal" }}>{item.name}</div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const controlNavbar = () => {
    // if (window.scrollY > lastScrollY) {
    if (window.scrollY === 0) {
      // if scroll down hide the navbar
      setShow(false);
    } else {
      // if scroll up show the navbar
      setShow(true);
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <div className={show ? "app-bar" : "app-bar-top"}>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <img
          src={image1}
          alt="qistar"
          style={{
            width: "7rem",
            height: "2.43rem",
          }}
        />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
        >
          <MenuIcon sx={{ color: "#fff" }} />
        </IconButton>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Button
            sx={{
              fontFamily: "font",
              color: "#fff",
            }}
            onClick={() => navigate("/")}
          >
            <div style={{ fontSize: "1vmax", fontFamily: "font-normal" }}>
              الرئيسية
            </div>
          </Button>
          <Button
            sx={{
              fontFamily: "font",
              color: "#fff",
            }}
            onClick={() => navigate("/memorandum")}
          >
            <div style={{ fontSize: "1vmax", fontFamily: "font-normal" }}>
              مذكرة تفاهم
            </div>
          </Button>
          {/* <Button
					sx={{
						fontFamily: "font",
						color: "#fff",
						// fontWeight: "600",
					}}
				>
					<div style={{ fontSize: "1vmax" }}>مستأجر</div>
				</Button>
				<Button
					sx={{
						fontFamily: "font",
						color: "#fff",
						// fontWeight: "600",
					}}
				>
					<div style={{ fontSize: "1vmax" }}>وسيط</div>
				</Button>
				<Button
					sx={{
						fontFamily: "font",
						color: "#fff",
						fontWeight: "normal",
					}}
				>
					<div style={{ fontSize: "1vmax" }}>مالك</div>
				</Button> */}
        </Box>
        {/* <Button
				variant="contained"
				sx={{
					fontFamily: "font",
					backgroundColor: "#fff",
					color: "#004A98",
					// fontWeight: 600,
					"&:hover": {
						backgroundColor: "#fff",
					},
				}}
				onClick={handleClickSignIn}
			>
				تسجيل دخول
			</Button> */}

        <Drawer
          container={window.document.body}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Container>
    </div>
  );
};

export default AppBar;
