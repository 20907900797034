import AppBar from "../LandingPage/AppBar";
import image1 from "../../assets/image1.svg";
import { Button, Container } from "@mui/material";
import ContractForm from "./ContractForm";
import Memorandum from "./Memorandum";
import Contact from "../LandingPage/Contact";
import Footer from "../LandingPage/Footer";
import { useEffect, useRef } from "react";

const Contract = () => {
  const formRef = useRef(null);
  const heroRef = useRef(null);

  const handleScroll = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    heroRef.current.scrollIntoView();
  }, []);

  return (
    <div className="landing-page-root" ref={heroRef}>
      <AppBar image1={image1} />
      <div className="hero-section-memo">
        {/* <video
          src={video}
          ref={vidRef}
          autoPlay
          muted
          loop
          className="video-bg"
        /> */}
        <div style={{ height: "100%", direction: "rtl" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              direction: "rtl",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
              className="land-w"
            >
              <div
                style={{
                  zIndex: "99",
                }}
              >
                <div className="hero-text-1">مذكرة تفاهم</div>
                <div className="hero-text-2">
                  نمو دائم لعقاراتك التجارية عبر تحصيل مبالغ التأجير مقدمًا، و
                  توسع مستمر عبرَ الاستئجار بأقساط شهرية ميسرة .
                </div>
                <Button variant="contained" sx={{ bgcolor: "#fff", mt: 5 }}>
                  <div
                    className="features-text1"
                    style={{
                      fontFamily: "font",
                      fontSize: "1.5rem",
                      lineHeight: "inherit",
                    }}
                    onClick={handleScroll}
                  >
                    سجل الآن
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Memorandum formRef={formRef} />
      <Contact />
      <Footer />
    </div>
  );
};

export default Contract;
