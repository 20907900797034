import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, Container, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import server from "../../../api/server";

const FormBody = () => {
  const [activeForm, setActiveForm] = useState(1);
  const [loadingSend, setLoadingSend] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    city: "",
    business_type: "",
    branch_no: "1",
    business: "Individually",
    property_type: "office",
  });
  const [client, setclient] = useState("Renter");

  const handleChange = (e) => {
    e.preventDefault();
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  //validation + go to step2
  const [validationError, setValidationError] = useState(null);
  const ValidationError = () => {
    return (
      validationError && (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", fontFamily: "font-normal" }}
        >
          {validationError}
        </div>
      )
    );
  };
  const handleNext = (step, feild) => {
    setValidationError(null);
    if (!formValues[feild]) {
      setValidationError("مطلوب");
      return;
    }
    setActiveForm(step);
  };

  const RenderForm = () => {
    if (activeForm === 1) {
      return form1();
    } else if (activeForm === 2) {
      return Form2();
    } else if (activeForm === 3) {
      return Form3();
    } else if (activeForm === 4) {
      return Form4();
    } else if (activeForm === 5) {
      return Form5();
    } else if (activeForm === 6) {
      return Form6();
    } else if (activeForm === 7) {
      return Form7();
    } else if (activeForm === 8) {
      return Form8();
    } else if (activeForm === 9) {
      return Form9();
    } else if (activeForm === 10) {
      return Form10();
    }
  };

  const handelChangeclient = (type) => {
    setclient(type);
  };

  const form1 = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal">الاسم</div>
        <div
          style={{
            marginTop: 5,
            marginBottom: 15,
            fontFamily: "font-normal",
          }}
        >
          <TextField
            fullWidth
            error={validationError}
            name="name"
            value={formValues.name}
            sx={{
              ".MuiOutlinedInput-input": {
                color: "#014E97",
                fontFamily: "font-normal",
              },
            }}
            onChange={handleChange}
          />
          {<ValidationError />}
        </div>
        <div>
          <LoadingButton
            // loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
            }}
            onClick={() => handleNext(2, "name")}
          >
            التالي
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form2 = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal">رقم التواصل</div>
        <div
          style={{
            marginTop: 5,
            marginBottom: 15,
            fontFamily: "font-normal",
          }}
        >
          <TextField
            fullWidth
            error={validationError}
            name="phone"
            value={formValues.phone}
            sx={{
              ".MuiOutlinedInput-input": {
                color: "#014E97",
                fontFamily: "font-normal",
              },
            }}
            onChange={(e) => handleChange(e)}
          />
          {<ValidationError />}
        </div>
        <div>
          <LoadingButton
            // loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
            }}
            onClick={() => handleNext(3, "phone")}
          >
            التالي
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form3 = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal">المدينة</div>
        <div
          style={{
            marginTop: 5,
            marginBottom: 15,
            fontFamily: "font-normal",
          }}
        >
          <TextField
            fullWidth
            error={validationError}
            name="city"
            value={formValues.city}
            sx={{
              ".MuiOutlinedInput-input": {
                color: "#014E97",
                fontFamily: "font-normal",
              },
            }}
            onChange={(e) => handleChange(e)}
          />
          {<ValidationError />}
        </div>
        <div>
          <LoadingButton
            // loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
            }}
            onClick={() => handleNext(4, "city")}
          >
            التالي
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form4 = () => {
    const nextstep = () => {
      if (client === "Renter") {
        handleNext(5, "name");
      } else if (client === "Broker") {
        handleNext(7, "name");
      } else if (client === "Owner") {
        handleNext(8, "name");
      }
    };
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal">هل انت؟</div>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={client === "Renter" ? "check-box2" : "check-box1"}
              onClick={() => handelChangeclient("Renter")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handelChangeclient("Renter")}
                checked={client === "Renter"}
              />
              <div className="text5">مستأجر</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={client === "Broker" ? "check-box2" : "check-box1"}
              onClick={() => handelChangeclient("Broker")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handelChangeclient("Broker")}
                checked={client === "Broker"}
              />
              <div className="text5">وسيط عقاري</div>
            </div>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <div
              className={client === "Owner" ? "check-box2" : "check-box1"}
              onClick={() => handelChangeclient("Owner")}
            >
              <Checkbox
                style={{
                  transform: "scale(0.7)",
                  width: "2rem",
                }}
                onClick={() => handelChangeclient("Owner")}
                checked={client === "Owner"}
              />
              <div className="text5">مالك عقار</div>
            </div>
          </Grid>
        </Grid>
        <div>
          <LoadingButton
            // loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
              mt: 2,
            }}
            onClick={() => nextstep()}
          >
            التالي
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form5 = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal">أخبرنا عن نوع نشاطكم التجاري</div>
        <div
          style={{
            marginTop: 5,
            marginBottom: 15,
            fontFamily: "font-normal",
          }}
        >
          <TextField
            fullWidth
            error={validationError}
            name="business_type"
            value={formValues.business_type}
            sx={{
              ".MuiOutlinedInput-input": {
                color: "#014E97",
                fontFamily: "font-normal",
              },
            }}
            onChange={(e) => handleChange(e)}
          />
          {<ValidationError />}
        </div>
        <div>
          <LoadingButton
            // loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
            }}
            onClick={() => handleNext(6, "business_type")}
          >
            التالي
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form6 = () => {
    const handleChangebranch = (value) => {
      setFormValues((prev) => ({ ...prev, branch_no: value }));
    };
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal">كم عدد فروعكم</div>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.branch_no === "1" ? "check-box2" : "check-box1"
              }
              onClick={() => handleChangebranch("1")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("1")}
                checked={formValues.branch_no === "1"}
              />
              <div className="text5">فرع واحد</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.branch_no === "2" ? "check-box2" : "check-box1"
              }
              onClick={() => handleChangebranch("2")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("2")}
                checked={formValues.branch_no === "2"}
              />
              <div className="text5">فرعين</div>
            </div>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.branch_no === "3" ? "check-box2" : "check-box1"
              }
              onClick={() => handleChangebranch("3")}
            >
              <Checkbox
                style={{
                  transform: "scale(0.7)",
                  width: "2rem",
                }}
                onClick={() => handleChangebranch("3")}
                checked={formValues.branch_no === "3"}
              />
              <div className="text5">ثلاثة فروع فأكثر</div>
            </div>
          </Grid>
        </Grid>
        <div>
          <LoadingButton
            loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
              mt: 2,
            }}
            onClick={handleSend}
          >
            إرسال
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form7 = () => {
    const handleChangebranch = (value) => {
      setFormValues((prev) => ({ ...prev, business: value }));
    };
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal" style={{ marginBottom: 10 }}>
          شريكنا سعيدين بخدمتك
        </div>
        <div className="text23 mr0 f-normal">هل أنت؟</div>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.business === "Individually"
                  ? "check-box2"
                  : "check-box1"
              }
              onClick={() => handleChangebranch("Individually")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("Individually")}
                checked={formValues.business === "Individually"}
              />
              <div className="text5">فرد</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.business === "Business" ? "check-box2" : "check-box1"
              }
              onClick={() => handleChangebranch("Business")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("Business")}
                checked={formValues.business === "Business"}
              />
              <div className="text5">شركة عقارية</div>
            </div>
          </Grid>
        </Grid>
        <div>
          <LoadingButton
            loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
              mt: 2,
            }}
            onClick={handleSend}
          >
            إرسال
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form8 = () => {
    const handleChangebranch = (value) => {
      setFormValues((prev) => ({ ...prev, business: value }));
    };
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal" style={{ marginBottom: 10 }}>
          عميلنا مالك العقار
        </div>
        <div className="text23 mr0 f-normal">هل أنت؟</div>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.business === "Individually"
                  ? "check-box2"
                  : "check-box1"
              }
              onClick={() => handleChangebranch("Individually")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("Individually")}
                checked={formValues.business === "Individually"}
              />
              <div className="text5">فرد</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.business === "Business" ? "check-box2" : "check-box1"
              }
              onClick={() => handleChangebranch("Business")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("Business")}
                checked={formValues.business === "Business"}
              />
              <div className="text5">شركة عقارية</div>
            </div>
          </Grid>
        </Grid>
        <div>
          <LoadingButton
            // loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
              mt: 2,
            }}
            onClick={() => handleNext(9, "city")}
          >
            التالي
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form9 = () => {
    const handleChangebranch = (value) => {
      setFormValues((prev) => ({ ...prev, property_type: value }));
    };
    return (
      <Box sx={{ width: "100%" }}>
        <div className="text23 mr0 f-normal">ما نوع عقارك التجاري</div>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.property_type === "exhibition"
                  ? "check-box2"
                  : "check-box1"
              }
              onClick={() => handleChangebranch("exhibition")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("exhibition")}
                checked={formValues.property_type === "exhibition"}
              />
              <div className="text5">معرض</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.property_type === "office"
                  ? "check-box2"
                  : "check-box1"
              }
              onClick={() => handleChangebranch("office")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("office")}
                checked={formValues.property_type === "office"}
              />
              <div className="text5">مكتب</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={
                formValues.property_type === "shop"
                  ? "check-box2"
                  : "check-box1"
              }
              onClick={() => handleChangebranch("shop")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handleChangebranch("shop")}
                checked={formValues.property_type === "shop"}
              />
              <div className="text5">محل</div>
            </div>
          </Grid>
        </Grid>
        <div>
          <LoadingButton
            loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !false
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
              mt: 2,
            }}
            onClick={handleSend}
          >
            إرسال
          </LoadingButton>
        </div>
      </Box>
    );
  };
  const Form10 = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <div className="features-text1" style={{ direction: "rtl" }}>
          شكرًا لاهتمامكم
        </div>
      </Box>
    );
  };

  const handleSend = async () => {
    setLoadingSend(true);
    try {
      const { data } = await server.post("/store-waitlist", {
        ...formValues,
        type: client,
      });
      if (data.data) {
        setActiveForm(10);
      }
    } catch (error) {
      console.log(error);
      setLoadingSend(false);
    }
  };
  return (
    <div style={{ direction: "rtl", backgroundColor: "rgba(241,241,241,0.9)" }}>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", alignItems: "center", minHeight: "500px" }}
      >
        {RenderForm()}
      </Container>
    </div>
  );
};

export default FormBody;
