import image3 from "../../assets/image2.svg";
// import image4 from "../../assets/macbook.png";
import image4 from "../../assets/labtop.png";
import image5 from "../../assets/shape1.png";
import check from "../../assets/check-solid.svg";
import { Button, Divider, Grid } from "@mui/material";

const Section2 = () => {
  return (
    <div className="section-2">
      <Grid container direction={"rtl"} spacing={0} sx={{ width: "100%" }}>
        <Grid
          item
          lg={5.5}
          md={5.5}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            alignItems: "center",
            height: "70vh",
          }}
        >
          <div className="box-l-1">
            <img
              src={image3}
              alt="qistar"
              style={{
                width: "6.32475rem",
                height: "2.1875rem",
              }}
            />
            <Divider sx={{ mt: "0px", mb: "10px" }} />
            <div className="section-text-1 f-normal">
              منصة إلكترونية عقارية لتسهيل دفعات الإيجار السنوي للمالك والإدارة
              المالية للمستأجر بالشراكة مع الوسطاء العقاريين من خلال حلول تقنية
              آمنة وبإجراءات سهلة ومرنة
            </div>
            <div className="s2-text1">لماذا تختار قسطار؟</div>
            <div className="frame16">
              <div className="icon-frame">
                <img src={check} alt="check" />
              </div>
              <div
                style={{ fontSize: "1rem" }}
                className="ForWho-text-9 f-light"
              >
                منصة وطنية مرخصة من هيئة العقار.
              </div>
            </div>
            <div className="frame16">
              <div className="icon-frame">
                <img src={check} alt="check" />
              </div>
              <div
                style={{ fontSize: "1rem" }}
                className="ForWho-text-9 f-light"
              >
                تكون بإجراءات سلسة.
              </div>
            </div>
            <div className="frame16">
              <div className="icon-frame">
                <img src={check} alt="check" />
              </div>
              <div
                style={{ fontSize: "1rem" }}
                className="ForWho-text-9 f-light"
              >
                تقديم حلول تصب بمصلحة مُلاك ومستأجري العقار التجاري.
              </div>
            </div>
            <div className="frame16">
              <div className="icon-frame">
                <img src={check} alt="check" />
              </div>
              <div
                style={{ fontSize: "1rem" }}
                className="ForWho-text-9 f-light"
              >
                حلول تقنية لإدارة المستحقات و تيسير الالتزام بِها للمستأجر.
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          lg={6.5}
          md={6.5}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "70vh",
            height: { xs: "auto", md: "70vh" },
          }}
        >
          <div className="img-section">
            <img
              className="img-section-1"
              src={image4}
              alt="image"
              style={{
                objectFit: "contain",
                zIndex: 1,
              }}
            />
            <img
              className="img-section-2"
              src={image5}
              alt="image"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: "0",
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Section2;
