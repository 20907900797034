import {
  Typography,
  Container,
  Grid,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import "./memorandum.css";
import dayjs from "dayjs";
import ContractForm from "./ContractForm";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import server from "../../api/server";
import { useNavigate } from "react-router-dom";
import SuccessPopup from "./SuccessPopup";

const Memorandum = ({ formRef }) => {
  const [formType, setFormType] = useState("renter");
  const [formInputs, setFormInputs] = useState({});
  const [loadingSend, setLoadingSend] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [is_agree, setIs_agree] = useState(0);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [link, setLink] = useState("");
  const navigate = useNavigate();

  //form validations
  const [serverError, setServerError] = useState({});

  const sendData = async () => {
    setServerError({});
    if (
      is_agree === 0 ||
      !formInputs.name ||
      !formInputs.city ||
      !formInputs.company_representer ||
      !formInputs.position_company_representer ||
      !formInputs.email ||
      !formInputs.branch_no ||
      !formInputs.phone
    ) {
      if (is_agree === 0) {
        setServerError((prev) => ({ ...prev, is_agree: ["الموافقة مطلوبة"] }));
      }
      if (!formInputs.name) {
        setServerError((prev) => ({ ...prev, name: ["مطلوب"] }));
      }
      if (!formInputs.city) {
        setServerError((prev) => ({ ...prev, city: ["مطلوب"] }));
      }
      if (!formInputs.company_representer) {
        setServerError((prev) => ({ ...prev, company_representer: ["مطلوب"] }));
      }
      if (!formInputs.email) {
        setServerError((prev) => ({ ...prev, email: ["مطلوب"] }));
      }
      if (!formInputs.position_company_representer) {
        setServerError((prev) => ({
          ...prev,
          position_company_representer: ["مطلوب"],
        }));
      }
      if (!formInputs.branch_no) {
        setServerError((prev) => ({ ...prev, branch_no: ["مطلوب"] }));
      }
      if (!formInputs.phone) {
        setServerError((prev) => ({ ...prev, phone: ["مطلوب"] }));
      }
      return;
    }
    setLoadingSend(true);
    try {
      const { data } = await server.post("/memorandum-understanding", {
        ...formInputs,
        is_agree,
        type: formType,
      });
      setLink(data.data.file);
      setLoadingSend(false);
      setFeedback(true);
      setSuccessPopupOpen(true);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 422) {
        setServerError(error.response.data.error);
      }
    }
    setLoadingSend(false);
  };
  const handelChangeFormType = (type) => {
    setFormType(type);
  };
  const handleChangeFormInputs = (input, value) => {
    setFormInputs((prev) => ({ ...prev, [input]: value }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFeedback(false);
  };
  const serverErrorMessage = (field) => {
    if (serverError[field]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", fontFamily: "font-normal" }}
        >
          {serverError[field][0]}
        </div>
      );
    }
  };
  return (
    <div style={{ width: "100%", direction: "rtl" }}>
      {/* <Snackbar open={feedback} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          <div className="text3">تم إرسال المذكرة بنجاح</div>
        </Alert>
      </Snackbar> */}
      <SuccessPopup
        open={successPopupOpen}
        email={formInputs.email}
        link={link}
      />
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginBlock: 4,
        }}
      >
        <div ref={formRef}></div>
        <Typography sx={{ fontFamily: "font-normal" }}>
          في هذا اليوم الموافق {dayjs().format("DD/MM/YYYY")} تم الاتفاق بين كل
          من:
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={formType === "renter" ? "check-box2" : "check-box1"}
              onClick={() => handelChangeFormType("renter")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handelChangeFormType("renter")}
                checked={formType === "renter"}
              />
              <div className="text5">مستأجر</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={formType === "broker" ? "check-box2" : "check-box1"}
              onClick={() => handelChangeFormType("broker")}
            >
              <Checkbox
                style={{ transform: "scale(0.7)" }}
                onClick={() => handelChangeFormType("broker")}
                checked={formType === "broker"}
              />
              <div className="text5">وسيط عقاري</div>
            </div>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <div
              className={formType === "owner" ? "check-box2" : "check-box1"}
              onClick={() => handelChangeFormType("owner")}
            >
              <Checkbox
                style={{
                  transform: "scale(0.7)",
                  width: "2rem",
                }}
                onClick={() => handelChangeFormType("owner")}
                checked={formType === "owner"}
              />
              <div className="text5">مالك عقار</div>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div
              className={formType === "platform" ? "check-box2" : "check-box1"}
              onClick={() => handelChangeFormType("platform")}
            >
              <Checkbox
                style={{
                  transform: "scale(0.7)",
                  width: "2rem",
                }}
                onClick={() => handelChangeFormType("platform")}
                checked={formType === "platform"}
              />
              <div className="text5">منصة عقارية</div>
            </div>
          </Grid>
        </Grid>
        <ContractForm
          formType={formType}
          form={formInputs}
          handleChange={handleChangeFormInputs}
          serverError={serverError}
        />
        <div
          style={{
            display: "flex",
            gap: 1,
            fontFamily: "font-normal",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={is_agree}
            onChange={() => setIs_agree((prev) => (prev === 0 ? 1 : 0))}
          />
          أوافق على بنود الاتفاقية
        </div>
        {serverErrorMessage("is_agree")}
        <div>
          <LoadingButton
            loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !loadingSend
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
            }}
            onClick={sendData}
          >
            إرسال
          </LoadingButton>
        </div>
        <ul className="list">
          <li>
            <Typography sx={{ fontFamily: "font-normal" }}>
              شركة الاختيار السهل العقارية (قسطار) بسجل تجاري رقم 1131330693
              وبريد إلكتروني: info@Qistar.sa، و يمثلها السيد/ صالح أحمد الحميد
              بصفته المدير التنفيذي (يشار إليه فيما بعد باسم "الطرف الأول").
            </Typography>
          </li>
          <li>
            {formType === "renter" ? (
              <Typography sx={{ fontFamily: "font-normal", mt: 1 }}>
                {formInputs.name || "………………………"}. ويقع عنوانها في مدينة{" "}
                {formInputs.city || "……………"}..، بريد إلكتروني:
                {formInputs.email || "...................................."}،
                عدد فروعها {formInputs.branch_no || "……"}، و يمثلها السيد/ة{" "}
                {formInputs.company_representer || "………………………"} بصفته{" "}
                {formInputs.position_company_representer || "……………………"} (يشار
                إليه فيما بعد باسم "الطرف الثاني")
              </Typography>
            ) : (
              <Typography sx={{ fontFamily: "font-normal", mt: 1 }}>
                {formInputs.name || "………………………"}. ويقع عنوانها في مدينة{" "}
                {formInputs.city || "……………"}..، بريد إلكتروني:
                {formInputs.email || "...................................."}،
                عدد عقاراتها {formInputs.branch_no || "……"}، و يمثلها السيد/ة{" "}
                {formInputs.company_representer || "………………………"} بصفته{" "}
                {formInputs.position_company_representer || "……………………"} (يشار
                إليه فيما بعد باسم "الطرف الثاني")
              </Typography>
            )}
          </li>
        </ul>
        <Typography sx={{ fontFamily: "font", textDecoration: "underline" }}>
          مقدمة:
        </Typography>
        <Typography sx={{ fontFamily: "font-normal" }}>
          منصة قسطار التقنية لحلول الايجار والتابعة لشركة الاختيار السهل منصة
          تقدم خدماتها في مجال التأجير العقاري لملاك العقارات والمستأجرين في
          القطاع التجاري والوسطاء العقاريين .
        </Typography>
        <Typography sx={{ fontFamily: "font-normal" }}>
          حيث يعتزم الطرفان في البحث عن سبل التعاون للشراكة التجارية فيما بينهما
          ، ولتحقيق هذا الهدف يدخل الطرفان في مذكرة التفاهم هذه لتأسيس العلاقة
          بينهما بناءً على نطاق العمل الموضح أدناه:
        </Typography>

        <Typography
          sx={{ fontFamily: "font", textDecoration: "underline", mt: 5 }}
        >
          مدة المذكرة:
        </Typography>

        <Typography sx={{ fontFamily: "font-normal" }}>
          تدخل هذه المذكرة حيز النفاذ في تاريخ التوقيع عليها من قبل الطرفين لمدة
          ٦ أشهر ولا تمثل هذه المذكرة أي التزام على الطرفين للدخول في أي اتفاق
          مستقبلي ولا يتم تجديدها إلا باتفاق مكتوب بين الطرفين و تنتهي تلقائياً
          عند توقيع عقد أو إتفاقية نهائية بين الطرفين أو عند إنهاء مذكرة التفاهم
          من قبل أحد الطرفين.
        </Typography>
        <Typography
          sx={{ fontFamily: "font", textDecoration: "underline", mt: 5 }}
        >
          مسؤوليات الأطراف:
        </Typography>
        <Typography sx={{ fontFamily: "font-normal" }}>
          2.1 أثناء مذكرة التفاهم هذه، و رهناً بنطاق التعاون بموجب هذه
          الاتفاقية، يلتزم الطرفان بتقديم الدعم اللازم لتنفيذ هذه الاتفاقية، بما
          في ذلك على السبيل المثال لا الحصر، توفير المعلومات و المستندات ، و ما
          إلى ذلك و التي يلزم فيها دعم الاتفاق.
        </Typography>
        <Typography
          sx={{ fontFamily: "font", textDecoration: "underline", mt: 5 }}
        >
          الإخطارات والمراسلات:
        </Typography>
        <Typography sx={{ fontFamily: "font-normal" }}>
          يتم تبادل الإشعارات و الإخطارات والمخاطبات المتعلقة بهذه المذكرة إما
          بتسليمها باليد لقاء إقرار خطي بالاستلام أو بالبريد المسجل أو البريد
          الإلكتروني وتعد مُنتجة لأثارها إذا بُّلغت بالطرق المذكورة، ويلتزم
          الطرفين عند القيام بتغير العنوان بإخطار الطرف الآخر بذلك قبل ثلاثين
          يوماً(٣٠) من التاريخ المحدد للتغير.
        </Typography>
        <Typography
          sx={{ fontFamily: "font", textDecoration: "underline", mt: 5 }}
        >
          إنهاء أو انتهاء هذه المذكرة:
        </Typography>
        <Typography sx={{ fontFamily: "font-normal" }}>
          لا يترتب على هذه الاتفاقية أي التزامات مالية على الطرفين و يجوز لأي من
          الطرفين إنهاء هذه المذكرة في أي وقت كان بموجب إشعار خطي يُّرسل للطراف
          الآخر على عنوانه المسجل في صدر هذه المذكرة.
        </Typography>
        <div
          style={{
            display: "flex",
            gap: 1,
            fontFamily: "font-normal",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={is_agree}
            onChange={() => setIs_agree((prev) => (prev === 0 ? 1 : 0))}
          />
          أوافق على بنود الاتفاقية
        </div>
        {serverErrorMessage("is_agree")}
        <div>
          <LoadingButton
            loading={loadingSend}
            variant="contained"
            sx={{
              fontFamily: "font-normal",
              paddingInline: 5,
              background: !loadingSend
                ? "linear-gradient(251.98deg, #0E005D 27.17%, #004A98 83.82%)"
                : "#909090",
            }}
            onClick={sendData}
          >
            إرسال
          </LoadingButton>
        </div>
      </Container>
    </div>
  );
};

export default Memorandum;
