import "./WhyQistar.css";
import arrow from "../../assets/arrow.svg";
import shape1 from "../../assets/Ellipse3.svg";
import shape2 from "../../assets/why-shape2.svg";
import vision from "../../assets/Vision.svg";

const WhyQistar = () => {
  return (
    <div className="why-root">
      <div></div>
      <div className="why-box1">
        {/*  */}
        <div className="why-box9">
          {/* <div className="why-text4">لماذا تختار قسطار؟</div> */}
          <div className="why-text5" style={{ textAlign: "center" }}>
            دفع نمو و توسع المشاريع التجارية وتحسين سوق التأجير العقاري لدعم
            استقراره وخلق بيئة جاذبة للاستثمار الطويل وتمكين الافراد والشركات
            التجارية العقارية.
          </div>
          <div className="why-text5" style={{ textAlign: "center" }}>
            يأتي قسطار نابعًا وتابعًا لتحقيق التحول الوطني و رؤية 2030 التي
            تستهدف النهضة بجميع القطاعات في الوطن.
          </div>
        </div>
      </div>
      <img src={vision} alt="shape" className="why-img" />
      {/* <div className="why-shape1"></div> */}
      {/* <div className="why-inner-shape1"></div> */}
      <img src={shape1} alt="shape" className="why-shape" />
      <img src={shape2} alt="shape" className="why-shape2" />
    </div>
  );
};

export default WhyQistar;
