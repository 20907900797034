import { Button, Divider, Grid } from "@mui/material";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/Union.svg";
import AppBar from "./AppBar";
import "./LandingPage.css";
import ForWhoSection from "./ForWhoSection";
import WhyQistar from "./WhyQistar";
import Partners from "./Partners";
import Features from "./Features";
import Contact from "./Contact";
import Footer from "./Footer";
import Section2 from "./Section-2";
import QuestionsSection from "./QuestionsSection";
import video from "../../assets/landingvideo.mp4";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import FormBody from "./registrationForm/FormBody";

const LandingPage = () => {
  const vidRef = useRef();
  const signupRef = useRef(null);

  const scroll = () => {
    signupRef?.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    vidRef.current.play();
  }, []);
  return (
    <div className="landing-page-root">
      <AppBar image1={image1} />
      <div className="hero-section">
        <video
          src={video}
          ref={vidRef}
          autoPlay
          muted
          loop
          className="video-bg"
        />
        <div className="hero-root"></div>
        <div style={{ height: "100%", direction: "rtl" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              direction: "rtl",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
              className="land-w"
            >
              <div
                style={{
                  zIndex: "99",
                }}
              >
                <div className="hero-text-1">شريك  النمو والتوسع</div>
                <div className="hero-text-2">
                  نمو دائم لعقاراتك التجارية عبر تحصيل مبالغ التأجير مقدمًا، و
                  توسع مستمر عبرَ الاستئجار بأقساط شهرية ميسرة .
                </div>
                <Button variant="contained" sx={{ bgcolor: "#fff", mt: 5 }}>
                  <div
                    className="features-text1"
                    style={{
                      fontFamily: "font",
                      fontSize: "1.5rem",
                      lineHeight: "inherit",
                    }}
                    onClick={scroll}
                  >
                    سجل الآن
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <QuestionsSection /> */}
      <Section2 />
      <ForWhoSection scroll={scroll} />
      <Features />
      <WhyQistar />
      {/* <Partners /> */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "rgba(241,241,241,0.9)",
          paddingTop: 30,

          // backgroundColor: "#dddddd",
        }}
        ref={signupRef}
      >
        <div style={{ width: "50%" }} className="f-text1">
          <div className="features-text1" style={{ direction: "rtl" }}>
            انضم إلينا الآن وكن جزءًا من تجربة استثنائية قبل الإطلاق الرسمي!
          </div>
          <div
            className="features-text1"
            style={{
              fontSize: "1.2rem",
              marginBlock: "1rem",
              direction: "rtl",
            }}
          >
            سجل اهتمامك الآن لتحصل على فرصة حصرية للاطلاع على مميزاتنا وأحد
            الأوائل الذين سيحظون بفوائد فريدة وتجربة مميزة
          </div>
        </div>
      </div>
      <FormBody />
      <Contact />
      <Footer />
    </div>
  );
};

export default LandingPage;
