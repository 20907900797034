import "./contact.css";
import mail from "../../assets/mail.svg";
import call from "../../assets/call.svg";
import location from "../../assets/location.svg";
import Grid from "@mui/material/Grid";

const Contact = () => {
  return (
    <div className="contact-root">
      <div className="contact-box">
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={4}
          >
            <div className="contact-box1">
              <div className="contact-text1">الشركة</div>
              <div className="contact-box3">
                <div className="contact-box6">
                  <div className="contact-text2">سياسة الخصوصية</div>
                </div>
                <div className="contact-box6">
                  <div className="contact-text2">الشروط والأحكام</div>
                </div>
                <div className="contact-box6">
                  <div className="contact-text2">الأسئلة الشائعة</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={4}
          >
            <div className="contact-box2">
              <div className="contact-text1">الصفحات</div>
              <div className="contact-box4">
                <div className="contact-box6">
                  <div className="contact-text2">الوسيط</div>
                </div>
                <div className="contact-box6">
                  <div className="contact-text2">المستأجر</div>
                </div>
                <div className="contact-box6">
                  <div className="contact-text2">المالك</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={4}
          >
            <div className="contact-box2">
              <div className="contact-text1">التواصل</div>
              <div className="contact-box5">
                <div className="contact-box6">
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:info@qistar.sa"
                  >
                    <div className="contact-text2">info@qistar.sa</div>
                  </a>
                  <img src={mail} alt="contact" />
                </div>
                {/* <div className="contact-box6">
                  <div className="contact-text2">0096123456780</div>
                  <img src={call} alt="contact" />
                </div> */}
                <div className="contact-box6">
                  <div className="contact-text2">
                    الرياض , المملكة العربيه السعودية
                  </div>
                  <img src={location} alt="contact" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Contact;
