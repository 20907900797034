import image1 from "../../assets/image1.svg";
import x from "../../assets/x.svg";
import facebook from "../../assets/facebook.svg";
import linkedin from "../../assets/linkedin.svg";
import instagram from "../../assets/instagram.svg";
import copy from "../../assets/copy.svg";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

const Footer = () => {
  return (
    <div className="app-bar-footer">
      <Container
        maxWidth="md"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          <Grid item xs={12} sm={4}>
            <img
              src={image1}
              alt="qistar"
              style={{
                width: "7vmax",
                height: "2.43vmax",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            display={"flex"}
            sx={{ justifyContent: { md: "center" } }}
          >
            <div className="footer-box">
              <div className="footer-text">جميع الحقوق محفوظة</div>
              <img src={copy} alt="copy" />
              <div className="footer-text">قسطار 2024</div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            // justifyContent={"flex-start"}
            display={"flex"}
            sx={{ justifyContent: { md: "flex-end" } }}
          >
            <div className="footer-icons">
              {/* <img src={x} alt="x.com" /> */}
              <a href="https://www.instagram.com/qistar_sa">
                <img src={instagram} alt="instagram" />
              </a>
              {/* <img src={linkedin} alt="linkedin.com" /> */}
              <a href="https://www.facebook.com/qistar_sa">
                <img src={facebook} alt="facebook.com" />
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
